<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title class="text-uppercase text-primary font-weight-bolder">
        Tiền cọc
      </b-card-title>
    </b-card-header>
    <b-card-body>

      <b-row class="mt-2">
        <b-col cols="12">
          <vue-good-table
            :columns="columns"
            :rows="rows"
            :search-options="{
              enabled: false,
            }"
            :select-options="{
              enabled: false,
            }"
          >
            <template
              slot="table-row"
              slot-scope="props"
            >
              <!-- Column: Code -->
              <span v-if="props.column.field === 'amount'">
                {{ Number(props.row.amount).toLocaleString() }}
              </span>

              <!-- Column: Common -->
              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>

            <div slot="emptystate">
              <div class="text-center text-muted">
                Không có bản ghi nào!
              </div>
            </div>
          </vue-good-table>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardBody, BCol, BRow,
} from 'bootstrap-vue';
import { VueGoodTable } from "vue-good-table";

export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BCol,
    BRow,
    VueGoodTable,
  },
  props: {
    rows: {
      type: Array,
      default: () => [],
    },
  },
  setup() {
    const columns = [
      {
        label: 'Tòa nhà',
        field: 'apartment.name',
      },
      {
        label: 'Phòng',
        field: 'room.name',
      },
      {
        label: 'Giường',
        field: 'bed.name',
      },
      {
        label: 'Khách hàng',
        field: 'name',
      },
      {
        label: 'Số tiền',
        field: 'amount',
        width: '180px',
        type: "number",
      },
      {
        label: 'Phân loại',
        field: 'type',
      },
    ];
    return { columns };
  },
};
</script>
