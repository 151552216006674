<template>
  <b-card
    v-if="data.length > 0"
    no-body
    class="card-revenue-budget"
  >
    <b-row class="mx-0">
      <b-col
        cols="12"
        class="revenue-report-wrapper"
      >
        <div class="d-sm-flex justify-content-between align-items-center mb-3">
          <h4 class="card-title mb-50 mb-sm-0">
            Dòng tiền
          </h4>

          <div class="d-flex align-items-center">
            <div class="d-flex align-items-center mr-2">
              <span class="bullet bullet-primary svg-font-small-3 mr-50 cursor-pointer" />
              <span>Doanh thu</span>
            </div>
            <div class="d-flex align-items-center mr-2">
              <span class="bullet bullet-warning svg-font-small-3 mr-50 cursor-pointer" />
              <span>Chi phí</span>
            </div>
            <div class="d-flex align-items-center mr-2">
              <span class="bullet bullet-success svg-font-small-3 mr-50 cursor-pointer" />
              <span>Lợi nhuận</span>
            </div>
            <b-dropdown
              v-model="apartment"
              :text="apartment ? apartment.name : 'Tất cả nhà'"
              variant="transparent"
              class="chart-dropdown border px-1 py-50"
              right
              no-caret
              toggle-class="p-0 mb-25"
              size="sm"
            >
              <b-dropdown-item
                v-for="eachApartment in data"
                :key="eachApartment.id"
                :value="eachApartment"
                @click="apartment = eachApartment"
              >
                {{ eachApartment.name }}
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </div>

        <!-- chart -->
        <vue-apex-charts
          v-if="apartment"
          id="revenue-report-chart"
          type="bar"
          height="480"
          :options="getChartOptions"
          :series="apartment.chart.series"
        />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BDropdown, BDropdownItem,
} from 'bootstrap-vue';
import VueApexCharts from 'vue-apexcharts';
import Ripple from 'vue-ripple-directive';

export default {
  components: {
    VueApexCharts,
    BDropdown,
    BDropdownItem,
    BCard,
    BRow,
    BCol,
  },
  directives: {
    Ripple,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      apartment: null,
    };
  },
  computed: {
    getChartOptions() {
      const { options } = this.apartment.chart;
      options.yaxis.labels = {
        ...options.yaxis.labels,
        formatter: y => {
          if (typeof y !== 'undefined') {
            return `${Number(y).toLocaleString()}`;
          }
          return y;
        },
      };
      return options;
    },
  },
  watch: {
    data(val) {
      this.apartment = val && val.length > 0 ? val[0] : null;
    },
  },

};
</script>
